// @flow
import styled from "styled-components";

export const ErrorMessage = styled.p`
  margin: 10px 0px 0px;
  color: ${({ theme }) => theme.palette.terracotta100};
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
`;
