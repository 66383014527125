export const Spinner = () => (
  <svg
    style={{ transform: "rotate(-90deg)" }}
    width="32"
    height="32"
    viewBox="0 0 32 32"
  >
    <circle
      cx="16"
      cy="16"
      r="10"
      fill="none"
      stroke="#353F52"
      strokeWidth="2"
      opacity="0.2"
    />
    <circle
      cx="16"
      cy="16"
      r="10"
      fill="none"
      stroke="#353F52"
      strokeWidth="2"
      strokeDasharray="62.8318530718"
      strokeDashoffset="62.8318530718"
      strokeLinecap="round"
    >
      <animate
        attributeName="stroke-dashoffset"
        values="62.8318530718;0;-62.8318530718"
        dur="3s"
        repeatCount="indefinite"
      />
    </circle>
  </svg>
);
