// @flow
import Downshift from "downshift";
import {
  forwardRef,
  createRef,
  useCallback,
  useState,
  useEffect,
  useContext,
} from "react";
import { useLazyQuery } from "@apollo/client/react/hooks";
import styled, { css, ThemeContext } from "styled-components";
import { gql } from "@apollo/client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import { sendAnalyticsEvent } from "@nested/analytics";
import { INVALID_POSTCODE } from "@nested/analytics/events";
import { Button } from "@nested/component-library";
import { Sentry } from "@nested/isomorphic-sentry";

import { TextInput } from "../TextInput/TextInput";
import { StatusIcon } from "./StatusIcon";
import { useDebounce } from "./useDebounce";
import { ErrorMessage } from "../ErrorMessage";

export const ADDRESS_NOT_FOUND_ID = "address_not_found";

export type Address = $ReadOnly<{|
  ...AddressPickerPostcodeDetailsLookup_postcodeDetails_addresses,
  blacklisted: boolean,
  postcodeInServicedArea: boolean,
|}>;

export type AddressNotFound = {
  id: typeof ADDRESS_NOT_FOUND_ID,
  postcode: string,
  blacklisted: boolean,
  postcodeInServicedArea: boolean,
};

const buttonIconWrapper = css`
  position: absolute;
  display: inline-block;
  right: 10px;
  bottom: 11px;
  height: 40px;
  div:last-child {
    margin-top: 3px;
    margin-right: 0px;
  }
`;

const buttonOverrideStyles = css`
  padding: 12px 15px;
  font-size: 14px;
  letter-spacing: 0.3px;
  line-height: 17px;
`;

const mapPin = css`
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 10;
  font-size: 20px;
`;

const focusedBorders = css`
  border: 1px solid
    ${({ theme, hasError, hasButtonBelow }) => {
      if (hasButtonBelow) {
        return theme.palette.hague40;
      }
      return hasError ? theme.palette.terracotta500 : theme.palette.hague;
    }};
`;

const unfocusedBorders = css`
  border: 1px solid
    ${({ theme, hasError, hasButtonBelow }) => {
      if (hasButtonBelow) {
        return theme.palette.hague40;
      }
      return hasError ? theme.palette.terracotta60 : theme.palette.hague40;
    }};
`;

const Ul = styled.ul`
  background-color: white;
  position: absolute;
  z-index: 10;
  top: calc(100% - 4px);
  left: 0;
  margin: 0px;
  width: calc(100% - 22px);
  max-height: 200px;
  overflow-y: auto;
  padding: 10px;
  list-style-type: none;
  text-align: left;
  border-radius: 0px 0px 4px 4px;
  color: ${({ theme }) => theme.palette.hague};
  box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.05);
  ${({ hasFocus }) => (hasFocus ? focusedBorders : unfocusedBorders)};
  border-top: none;
  transition: border 200ms ease;
`;

const Item = styled.li`
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 5px;
  border: 1px solid transparent;
  color: ${({ theme }) => theme.palette.hague};
  cursor: pointer;

  ${({ isActive, theme }) =>
    isActive &&
    css`
      background-color: ${theme.palette.terracotta20};
      border: 1px solid ${theme.palette.terracotta40};
    `}

  width: calc(100% - 22px);
`;

const ErrorMessageLi = styled.li`
  padding: 10px;
  color: ${({ theme }) => theme.palette.hague};
`;

export const POSTCODE_DETAILS = gql`
  query AddressPickerPostcodeDetailsLookup($postcode: String!) {
    postcodeDetails(postcode: $postcode) {
      valid
      inServicedArea
      normalisedPostcode
      reasonInvalid
      blacklisted
      latitude
      longitude
      addresses {
        id
        label
        postcode
        town
        isListed
        currentAgent
        currentPrice
        currentStatus
        weeksOnMarket
      }
    }
  }
`;

export type AddressPickerStateOverride = {
  menuOpen?: boolean,
  postcode?: string,
  loading?: boolean,
};

type Props = {
  buttonDisabled?: boolean,
  className?: string,
  dataTest?: string,
  debounceTimeout?: number,
  errorMessage?: string,
  hasButtonBelow?: boolean,
  hasErrors?: boolean,
  includeBlacklisted?: boolean,
  includeNotInServicedArea?: boolean,
  allowAddressNotFound?: boolean,
  label?: string,
  name: string,
  notInServicedAreaErrorMessage?: string,
  onChange(value: Address): void,
  placeholder?: string,
  prefill?: {
    postcode: string,
    addressId: string,
  },
  submitButtonText?: string,
  stateOverride?: AddressPickerStateOverride,
  value: Address | AddressNotFound | null, // eslint-disable-line
  withPin?: boolean,
};

/**
 * This component handles postcode entry and address selection across our site.
 *
 * If the address is selected, it shows the full address (label + postcode), but
 * when you click on the input field it reverts to showing the postcode only
 * with a list of addresses in a dropdown. Therefore it needs to have 2 types of
 * internal state:
 * 1) postcode value that is currently being edited (or hidden)
 * 2) address object (unique address id, postcode, label, town)
 *
 * Postcode value (1) can be modified by the user through his interaction with
 * the input field.
 *
 * Address value (2) can only be selected from a list of options we receive from
 * the backend and is update using the "onChange" prop received form the parent.
 */
export const AddressPicker = forwardRef<Props, HTMLInputElement>(
  (
    {
      buttonDisabled,
      className,
      dataTest = "address-picker-with-button",
      debounceTimeout,
      includeBlacklisted = false,
      includeNotInServicedArea = false,
      allowAddressNotFound = false,
      errorMessage,
      hasButtonBelow,
      hasErrors,
      label,
      name,
      notInServicedAreaErrorMessage = "Sorry, we're not in your area yet!",
      onChange,
      placeholder,
      prefill,
      stateOverride,
      submitButtonText,
      value,
      withPin = false,
    },
    ref,
  ) => {
    const initialPostcode = stateOverride?.postcode || prefill?.postcode || "";
    const [postcode, setPostcode] = useState(initialPostcode);
    const [hasFocus, setFocus] = useState(false);
    const theme = useContext(ThemeContext);

    /*
     * Had to put debounce function in state as it holds state itself and needs to
     * retain its state on re-render.
     */
    const debouncedSetPostcode = useDebounce(
      setPostcode,
      debounceTimeout || 500,
    );
    const [getPostcodeDetails, { data, error, loading }] =
      useLazyQuery(POSTCODE_DETAILS);

    // On mount, queries for postcode details if postcode pre-filled
    useEffect(() => {
      const prefilledPostcode = prefill?.postcode;
      if (prefilledPostcode) {
        getPostcodeDetails({
          variables: {
            postcode: prefilledPostcode,
          },
        });
      }
    }, []);

    // For prefilled addressIds, automatically selects address
    useEffect(() => {
      if (!prefill || !data || !data.postcodeDetails) return;

      const {
        latitude: postcodeLat,
        longitude: postcodeLon,
        inServicedArea: postcodeInServicedArea,
        blacklisted,
        addresses,
      } = data.postcodeDetails;

      if (prefill.addressId && addresses) {
        const address = addresses.find((a) => a.id === prefill.addressId);

        if (address) {
          onChange({
            ...address,
            blacklisted,
            postcodeLat,
            postcodeLon,
            postcodeInServicedArea,
          });
        }
      }
    }, [loading]);

    /*
     * On component update, queries for current postcode if different from the last
     * queried postcode.
     *
     * We are checking to make sure that postcode length is between 5 and 8
     * characters so that we don't try to validate postcodes that are obviously
     * wrong. This also helps to avoid validating the postcode when the input
     * value is displayed as a full address. Minimum postcode length is 6, but 5
     * is for cases when a user skips a space, which the backend can handle for us.
     *
     * Source for postcode lengths:
     * https://en.wikipedia.org/wiki/Postcodes_in_the_United_Kingdom
     */
    useEffect(() => {
      if (postcode && postcode.length >= 5 && postcode.length <= 8) {
        getPostcodeDetails({ variables: { postcode } });
      }
    }, [postcode]);

    useEffect(() => {
      if (data?.postcodeDetails?.valid === false) {
        sendAnalyticsEvent({
          event: INVALID_POSTCODE,
          postcode,
        });
      }
    });

    const postcodeDetails = data?.postcodeDetails;

    const itemToString = (item) => {
      if (item?.id === ADDRESS_NOT_FOUND_ID) {
        return "Address not found";
      }
      return item ? `${item.label}, ${item.postcode}` : "";
    };

    const downshiftStateReducer = useCallback((state, changes) => {
      switch (changes.type) {
        /**
         * For whatever reason Downshift's `Downshift.stateChangeTypes.undefined`
         * change type does not match (even though in console it is there). Had
         * to match on `undefined` change type. This happens when menu is opened
         * programatically using Downshift's `openMenu()`. We are doing this when
         * the input method is focused.
         */
        case undefined:
          // We only one to "reset" the value if user hasn't modified it
          if (
            state.selectedItem &&
            state.inputValue === itemToString(state.selectedItem)
          ) {
            return {
              ...changes,
              inputValue: state.selectedItem.postcode,
            };
          }
          return {
            ...changes,
            inputValue: state.inputValue,
          };
        default:
          return changes;
      }
    });

    /**
     * Downshift doesn't automatically blur the input field when an item is
     * selected, so we have to manually get hold of the text input ref and blur
     * the field on selection.
     */
    const textInputRef =
      (typeof ref !== "function" && ref) || createRef<HTMLInputElement>();

    const showServicedAreaError = includeNotInServicedArea
      ? false
      : !postcodeDetails?.inServicedArea;

    const showBlacklistedAreaError = includeBlacklisted
      ? false
      : postcodeDetails?.blacklisted;

    const outsideArea = showServicedAreaError || showBlacklistedAreaError;

    return (
      <>
        <Downshift
          initialInputValue={
            value && value.id !== ADDRESS_NOT_FOUND_ID
              ? `${value.label}, ${value.postcode}`
              : initialPostcode
          }
          initialSelectedItem={value}
          onInputValueChange={(inputValue) => debouncedSetPostcode(inputValue)}
          initialIsOpen={stateOverride?.menuOpen || false}
          itemToString={itemToString}
          onChange={(selectedItem) => {
            try {
              /**
               * We want to make sure that we have a valid element to blur. If for
               * some reason we don't have an element and try to blur it the whole
               * page blows up.
               */
              if (
                textInputRef.current &&
                typeof textInputRef.current.blur === "function"
              ) {
                textInputRef.current.blur();
              }

              // Somehow it's possible for postcodeDetails to be null here
              onChange({
                ...selectedItem,
                blacklisted: postcodeDetails.blacklisted,
                postcodeInServicedArea: postcodeDetails.inServicedArea,
              });
            } catch (e) {
              Sentry.captureException(e, {
                extra: {
                  apolloError: JSON.stringify(error),
                  apolloData: JSON.stringify(data),
                  selectedItem: JSON.stringify(selectedItem),
                  postcode,
                },
              });
            }
          }}
          stateReducer={downshiftStateReducer}
        >
          {({
            getInputProps,
            getItemProps,
            getMenuProps,
            getRootProps,
            highlightedIndex,
            isOpen,
            openMenu,
          }) => {
            return (
              <div
                css={css`
                  position: relative;
                `}
                className={className}
                {...getRootProps({}, { suppressRefError: true })}
              >
                {withPin && (
                  <FontAwesomeIcon
                    color={theme.palette.terracotta100}
                    icon={faMapMarkerAlt}
                    css={mapPin}
                  />
                )}
                <TextInput
                  {...getInputProps()}
                  autoComplete="off"
                  data-test={dataTest}
                  hasButton={submitButtonText}
                  hasButtonBelow={hasButtonBelow}
                  hasErrors={hasErrors}
                  withIcon={withPin}
                  label={label}
                  name={name}
                  onFocus={() => {
                    setFocus(true);
                    openMenu();
                  }}
                  onBlur={() => {
                    setFocus(false);
                  }}
                  placeholder={placeholder}
                  ref={textInputRef}
                />

                {isOpen && error && (
                  <Ul
                    hasFocus={hasFocus}
                    hasError={hasErrors}
                    hasButtonBelow={hasButtonBelow}
                  >
                    <li
                      css={css`
                        padding: 10px;
                      `}
                      data-test="address-fetch-error"
                    >
                      Failed to load addresses. Please try again later.
                    </li>
                  </Ul>
                )}
                {isOpen && postcodeDetails && (
                  <Ul
                    {...getMenuProps()}
                    hasFocus={hasFocus}
                    hasError={hasErrors}
                    hasButtonBelow={hasButtonBelow}
                  >
                    {!postcodeDetails.valid && (
                      <ErrorMessageLi data-test="invalid-postcode">
                        Please enter a valid postcode
                      </ErrorMessageLi>
                    )}

                    {postcodeDetails.valid && outsideArea && (
                      <ErrorMessageLi data-test="not-in-serviced-area">
                        {notInServicedAreaErrorMessage}
                      </ErrorMessageLi>
                    )}

                    {postcodeDetails.valid &&
                      postcodeDetails.inServicedArea &&
                      postcodeDetails.addresses.length === 0 && (
                        <ErrorMessageLi data-test="no-addresses">
                          Sorry, we couldn&apos;t find any addresses for that
                          postcode
                        </ErrorMessageLi>
                      )}

                    {postcodeDetails.valid &&
                      !outsideArea &&
                      postcodeDetails.addresses.length > 0 &&
                      postcodeDetails.addresses.map((item, index) => (
                        <Item
                          data-test="address-picker-menu-item"
                          {...getItemProps({
                            key: item.id,
                            index,
                            item,
                          })}
                          isActive={highlightedIndex === index}
                        >
                          {item.label}, {item.postcode}
                        </Item>
                      ))}
                    {postcodeDetails.valid && allowAddressNotFound && (
                      <Item
                        data-test="address-picker-not-found"
                        {...getItemProps({
                          key: "not-found",
                          index: postcodeDetails?.addresses?.length || 0,
                          item: {
                            id: ADDRESS_NOT_FOUND_ID,
                            blacklisted: postcodeDetails?.blacklisted,
                            postcodeInServicedArea:
                              postcodeDetails?.inServicedArea,
                            postcode:
                              postcodeDetails?.normalisedPostcode || postcode,
                          },
                        })}
                        isActive={
                          highlightedIndex ===
                            postcodeDetails?.addresses?.length || 0
                        }
                      >
                        Can't find your address?
                      </Item>
                    )}
                  </Ul>
                )}
                <div css={buttonIconWrapper}>
                  <StatusIcon
                    loading={stateOverride?.loading || loading}
                    warning={
                      (data &&
                        (!data?.postcodeDetails?.valid ||
                          showServicedAreaError)) ||
                      error
                    }
                  />
                  {submitButtonText && (
                    <Button
                      css={buttonOverrideStyles}
                      noDisabledStyle
                      type="accent"
                      data-test="address-picker-submit-button"
                      disabled={buttonDisabled}
                      colour="hague"
                      inverted
                    >
                      {submitButtonText}
                    </Button>
                  )}
                </div>
              </div>
            );
          }}
        </Downshift>
        {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      </>
    );
  },
);
