// @flow
import styled from "styled-components";
import { Spinner, Warning } from "@nested/ui/icons";

const Wrapper = styled.div`
  z-index: 2;
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
  svg {
    display: inline-block;
    vertical-align: middle;
  }
`;

type Props = {
  loading?: boolean,
  warning?: boolean,
};

const StatusIcon = ({ loading, warning }: Props) => {
  if (loading) {
    return (
      <Wrapper className="chromatic-ignore">
        <Spinner />
      </Wrapper>
    );
  }
  if (warning) {
    return (
      <Wrapper>
        <Warning />
      </Wrapper>
    );
  }
  return null;
};

export { StatusIcon };
