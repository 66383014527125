export const Warning = ({ fill = "#353F52" }) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      id="Ellipse 2"
      d="M17.5 21.5C17.5 22.3284 16.8284 23 16 23C15.1716 23 14.5 22.3284 14.5 21.5C14.5 20.6716 15.1716 20 16 20C16.8284 20 17.5 20.6716 17.5 21.5Z"
      fill={fill}
    />
    <path id="Rectangle" d="M15 11H17V19H15V11Z" fill={fill} />
    <path
      id="Rectangle 2 (Stroke)"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.02003 25H24.9801C26.5298 25 27.4907 23.3136 26.7007 21.9804L17.7207 6.82657C16.946 5.51938 15.0541 5.51938 14.2795 6.82657L5.29944 21.9804C4.5094 23.3136 5.47032 25 7.02003 25ZM24.9801 27H7.02003C3.9206 27 1.99877 23.6272 3.57886 20.9608L12.5589 5.80697C14.1082 3.19258 17.892 3.19258 19.4412 5.80697L28.4213 20.9608C30.0014 23.6272 28.0795 27 24.9801 27Z"
      fill={fill}
    />
  </svg>
);
