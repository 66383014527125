// @flow
export const ACCOUNT_LOGIN = "successfulAccountLogin";

export const INVALID_POSTCODE = "invalidPostcode";

export const AREA_CHECK_GATE = "areaCheckGate";

export const AREA_CHECK_GATE_NOT_IN_AREA = "postcodeEnteredNotInAreaCheckGate";

export const PERFORMANCE_TRACKER_SHOW_ANALYSIS =
  "performanceTrackerShowAnalysis";

export const SELECT_PROPERTY_ADDRESS = "selectPropertyAddress";

export const SELECT_PROPERTY = "selectProperty";

export const OFFER_REQUEST_SUCCESSFUL = "offerRequestSuccessful";

export const ACCOUNT_SIGNUP_SUCCESSFUL = "accountSignupSuccess";

export const AVM_SUBMIT = "avmSubmitAddress";

export const AVM_SUCCESSFUL_VALUATION = "avmSuccessfulValuation";

export const AVM_VISIT_BOOKED = "avmHomeVisitBooked";

export const AVM_FAILED_VALUATION = "avmFailedValuation";

export const LISTING_HISTORY_RESULTS = "listingHistoryResults";

export const SELLING_TIMELINE_SUBMIT = "sellingTimelineSubmit";

export const SELLING_TIMELINE_SUCCESS = "sellingTimelineSuccess";

export const SELLING_TIMELINE_FAILURE = "sellingTimelineFailure";

export const VIEWING_FEEDBACK_BUYER_INFO = "viewingFeedbackBuyerInfo";

export const ONBOARDING_FORM_QUESTION_ANSWERED =
  "onboardingFormQuestionAnswered";

export const ONBOARDING_FORM_PERSONAL_SUBMITTED =
  "onboardingFormPersonalInfoSubmitted";

export const ONBOARDING_FORM_PROPERTY_SUBMITTED =
  "onboardingFormPropertyInfoSubmitted";

export const ONBOARDING_FORM_FINISH_UP_SUBMITTED =
  "onboardingFormFinishUpSubmitted";
