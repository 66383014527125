// @flow
import { forwardRef } from "react";
import { css } from "styled-components";
import { mediumParagraph } from "../Typography/Paragraphs";
import { ErrorMessage } from "../ErrorMessage";

const hasButtonBelowStyles = css`
  border-radius: 4px 4px 0 0;
  border-bottom: none;
`;
const hasButtonBelowFocusStyles = css`
  border: 1px solid ${({ theme }) => theme.palette.hague40};
  border-bottom: none;
`;

const inputStyles = css`
  -webkit-appearance: none;
  background-color: white;
  border-radius: 4px;
  border: 1px solid
    ${({ theme, hasErrors }) =>
      hasErrors ? theme.palette.terracotta60 : theme.palette.hague40};
  box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
  color: ${({ theme }) => theme.palette.hague};
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  width: 100%;

  ${({ center }) => center && "text-align: center;"}

  padding: 19px 12px;
  padding-right: ${({ hasButton }) => (hasButton ? "130px" : "12px")};
  padding-left: ${({ withIcon }) => (withIcon ? "50px" : "12px")};
  text-overflow: ellipsis;
  transition: border 200ms ease;

  :focus {
    border: 1px solid
      ${({ hasErrors, theme }) =>
        hasErrors ? theme.palette.terracotta500 : theme.palette.hague};
    ${({ hasButtonBelow }) => hasButtonBelow && hasButtonBelowFocusStyles}
  }

  &:disabled {
    background-color: #e9eef5;
  }

  &::placeholder {
    color: ${({ theme }) => theme.palette.hague40};
  }

  ${({ label }) => label && `margin-top: 5px`}

  ${({ hasButtonBelow }) => hasButtonBelow && hasButtonBelowStyles}
`;

type Props = {|
  center?: boolean,
  className?: string,
  "data-test"?: string,
  errorMessage?: string,
  hasButtonBelow?: boolean,
  hasErrors?: boolean,
  icon?: string,
  id?: string,
  label?: string,
  name?: string,
  onChange(e: SyntheticInputEvent<HTMLInputElement>): void,
  placeholder?: string,
  type?: string,
  value: string,
  withIcon?: boolean,
  disabled?: boolean,
  css?: any,
|};

export const TextInput = forwardRef<Props, *>(
  (
    {
      "data-test": dataTest,
      center,
      className,
      errorMessage,
      hasButtonBelow,
      hasErrors,
      icon,
      id,
      label,
      name,
      onChange,
      placeholder,
      type = "text",
      value,
      withIcon,
      ...rest
    },
    ref,
  ) => (
    <div className={className}>
      {label && (
        <label htmlFor={id} css={mediumParagraph}>
          <strong>{label}</strong>
        </label>
      )}
      <input
        center={center}
        css={inputStyles}
        data-test={dataTest}
        hasButtonBelow={hasButtonBelow}
        hasErrors={hasErrors}
        icon={icon}
        id={id}
        label={label}
        name={name}
        onChange={onChange}
        placeholder={placeholder}
        ref={ref}
        type={type}
        value={value}
        withIcon={withIcon}
        {...rest}
      />
      {errorMessage && (
        <ErrorMessage data-test="form-submit-error">
          {errorMessage}
        </ErrorMessage>
      )}
    </div>
  ),
);
