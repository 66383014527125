// @flow
import { sendAnalyticsEvent } from "@nested/analytics";
import { SELECT_PROPERTY } from "@nested/analytics/events";

export const onboardingCtaHandler =
  (ctaName: string, history: { push: (path: string) => void }) =>
  (
    // eslint-disable-next-line
    details: AddressPickerPostcodeDetailsLookup_postcodeDetails_addresses & {
      blacklisted: boolean,
    },
  ) => {
    const {
      id,
      postcode,
      blacklisted,
      isListed,
      label,
      currentAgent,
      currentPrice,
      currentStatus,
      weeksOnMarket,
      postcodeInServicedArea,
    } = details[ctaName];
    sendAnalyticsEvent({
      event: SELECT_PROPERTY,
      id,
      isListed,
      label,
      postcode,
      currentAgent,
      currentPrice,
      currentStatus,
      weeksOnMarket,
      postcodeInServicedArea,
    });
    if (postcodeInServicedArea === false) {
      history.push(
        `/get-started/coming-soon?postcode=${postcode}&addressId=${id}`,
      );
    } else if (blacklisted) {
      history.push(
        `/get-started/postcode-not-in-serviced-area?postcode=${postcode}&addressId=${id}`,
      );
    } else {
      history.push(`/get-started?postcode=${postcode}&addressId=${id}`);
    }
  };
