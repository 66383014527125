// @flow
import { useHistory } from "react-router";
import { css } from "styled-components";
import { Field, Form } from "react-final-form";
import { media } from "@nested/brand";
import { RichText as PrismicRichText } from "prismic-reactjs";
import { ResponsiveImage } from "../../components/ResponsiveImage";
import {
  onboardingCtaHandler,
  AddressPicker,
  type AddressPickerStateOverride,
} from "../../components/AddressPicker";
import {
  regularHeading,
  largeHeading,
} from "../../components/Typography/Headings";
import { mediumParagraph } from "../../components/Typography/Paragraphs";

const backgroundStyle = css`
  align-items: center;
  background-color: ${({ theme }) => theme.palette.hague};
  display: flex;
  flex-direction: column;
  padding: 40px 20px;
  ${media.tablet`
    padding: 60px 20px;
  `}
  ${media.desktop`
    padding: 80px 20px;
  `}
`;

const headingStyle = css`
  ${regularHeading}
  color: white;
  margin: 20px 0 0 0;
  text-align: center;
  white-space: pre-wrap;
  ${media.tablet`
    ${largeHeading}
    color: white;
    max-width: 510px;
  `}
`;

const imageWrapperStyle = css`
  background-color: #ffeef1;
  border-radius: 70px 70px 0 0;
  height: 120px;
  margin: 0px 20px 0 20px;
  position: relative;
  width: 101px;
`;

const paragraphStyle = css`
  text-align: center;
  p {
    ${mediumParagraph}
    color: white;
    margin: 20px 0 0 0;
  }
  ${media.tablet`
    max-width: 520px;
  `}
`;

const formStyles = css`
  margin-top: 30px;
  position: relative;
  width: 100%;
  max-width: 290px;
  ${media.tablet`
    max-width: 348px;
  `}
`;

const imageStyle = css`
  left: -15px;
  position: absolute;
  top: 9px;
  width: 125px;
`;

const required = (value) => (value ? undefined : "Required");

type Props = {
  description: PrismicRichText,
  heading: string,
  image: {
    url: string,
    alt: string,
  },
  stateOverride?: {
    "bookend-address": Address,
    "bookend-address-stateOverride": AddressPickerStateOverride,
  },
  submitButtonText: string,
};

export const Bookend = ({
  description,
  heading,
  image,
  stateOverride,
  submitButtonText,
}: Props) => {
  const history = useHistory();
  const onSubmit = onboardingCtaHandler("bookend-address", history);

  return (
    <div css={backgroundStyle}>
      <div css={imageWrapperStyle}>
        <ResponsiveImage css={imageStyle} src={image.url} alt={image.alt} />
      </div>
      <h3 css={headingStyle}>{heading}</h3>
      <div css={paragraphStyle}>{PrismicRichText.render(description)}</div>
      <Form
        onSubmit={onSubmit}
        initialValues={{
          "bookend-address":
            (stateOverride && stateOverride["bookend-address"]) || null,
        }}
      >
        {({ handleSubmit, pristine, submitting, hasValidationErrors }) => (
          <form onSubmit={handleSubmit} css={formStyles}>
            <Field validate={required} name="bookend-address">
              {({ input, meta }) => (
                <AddressPicker
                  dataTest="bookend-address"
                  placeholder="Enter postcode"
                  buttonDisabled={pristine || hasValidationErrors || submitting}
                  submitButtonText={submitButtonText}
                  includeBlacklisted
                  stateOverride={
                    stateOverride &&
                    stateOverride["bookend-address-stateOverride"]
                  }
                  {...input}
                  {...meta}
                />
              )}
            </Field>
          </form>
        )}
      </Form>
    </div>
  );
};
